<script setup>

import {computed, onMounted, ref} from "vue";
import ButtonPrimary from "../../../buttons/buttonPrimary.vue";

const props = defineProps({
    content: {
        type: Object,
        required: true,
    }
})

const courses = ref([]);
const coursesLoaded = ref(false);
const userIsLoggedIn = window.myflow.user.loggedIn;

const loadCourses = async () => {
    const res = await axios.get(route('api.course.customerarea.index'));
    if (res.data.success)    {
        courses.value = res.data.courses
        coursesLoaded.value = true
    }
};

const reloadPage = () => {
    window.location.reload();
}

const pageBuilderActive = computed(() => {
    return window.myflow?.pagebuilderActive;
});

onMounted(() => {
    if (userIsLoggedIn) {
        loadCourses();
    }
});

</script>

<template>
    <div>
        <h2 v-if="content.title">{{ content.title }}</h2>
        <div v-if="userIsLoggedIn">
            <div v-if="pageBuilderActive">
                <list-basic :list-class="content.version === 2 ? '' : 'mt-2 bg-white shadow-xl rounded-wl'">
                    <list-basic-item>
                        <template #title>
                            <div class="text-center text-gray-500 py-4">{{ _mft('pagebuilderBlock:licensedCourses.placeholder.listItem') }}</div>
                        </template>
                        <template #actions>
                            <span></span>
                        </template>
                    </list-basic-item>
                </list-basic>
            </div>
            <div v-else>
                <div
                    v-if="coursesLoaded"
                    class="pb-8"
                >
                    <p
                        v-if="courses.length === 0"
                        class="text-center py-4"
                    >
                        {{ content.noCoursesMessage }}
                    </p>

                    <list-basic v-else :list-class="content.version === 2 ? '' : 'mt-2 bg-white shadow-xl rounded-wl'">
                        <list-basic-item
                            v-for="(course, index) in courses"
                        >
                            <template #title>
                                <a
                                    :href="route('course.customerarea.show', course.id)"
                                    class="mf-underline text-inherit"
                                >
                                    {{ course.title }}
                                </a>
                            </template>
                            <template #actions>
                                <a
                                    :href="route('course.customerarea.show', course.id)"
                                    class="mf-underline text-inherit"
                                >
                                    <button-primary
                                        size="sm"
                                    >
                                        {{ _mft('shared:action.show') }}
                                    </button-primary>
                                </a>
                            </template>
                        </list-basic-item>
                    </list-basic>
                </div>
                <mf-spinner-medium v-else class="w-24 h-12" />
            </div>
        </div>
        <login-form
            v-else
            class="my-4"
            @successfulLogin="reloadPage"
        />
    </div>

</template>

<style scoped>

</style>
